import axios, { AxiosInstance } from 'axios';
import {
  DataProvider, HttpError, CrudOperators, CrudFilters, CrudSorting, BaseKey, LogicalFilter,
} from '@refinedev/core';
import Qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import queryString from 'query-string';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case 'in':
    case 'ne':
    case 'gt':
    case 'lt':
    case 'gte':
    case 'lte':
    case 'between':
      return `$${operator}`;
    case 'contains':
      return '$search';
    case 'eq':
    default:
      return '';
  }
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const $sort: {[k:string]:number} = {};

    sort.forEach((item) => {
      $sort[`$sort[${item.field}]`] = item.order === 'asc' ? 1 : -1;
    });

    return $sort;
  }
  return [];
};

const generateFilter = (filters?: CrudFilters, searchMode: string = '') => {
  const queryFilters: { [key: string]: any } = {};
  if (filters) {
    filters.forEach((filter) => {
      const { field, operator, value } = filter as LogicalFilter;
      let newValue = value;

      if (newValue instanceof dayjs) {
        newValue = (newValue as Dayjs).toDate();
      } else if (Array.isArray(newValue)) {
        newValue = newValue.map((e) => ((e instanceof dayjs) ? (e as Dayjs).toDate() : e));
      }

      if (field === 'q') {
        queryFilters[field] = newValue;
        return;
      }

      if (operator === 'between') {
        if (Array.isArray(newValue) && newValue.length === 2) {
          if (dayjs(newValue[0]).isValid() && dayjs(newValue[1]).isValid()) {
            newValue[0] = dayjs(newValue[0]).toISOString();
            newValue[1] = dayjs(newValue[1]).toISOString();
          }
          queryFilters[field] = { $gte: newValue[0], $lte: newValue[1] };
        }
        return;
      }

      if (operator === 'eq') {
        if (searchMode === 'startWith') {
          queryFilters[field] = `^${newValue}`;
        } else {
          queryFilters[field] = newValue;
        }
      } else {
        const mappedOperator = mapOperator(operator);
        if (searchMode === 'startWith') {
          queryFilters[`${field}[${mappedOperator}]`] = `^${newValue}`;
        } else {
          queryFilters[`${field}[${mappedOperator}]`] = newValue;
        }
      }
    });
  }

  return queryFilters;
};

const FeathersDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({
  getList: async ({
    resource, pagination, filters, sort, meta,
  }) => {
    let url = `${apiUrl}/${resource}`;
    const {
      options: queryOptions = {},
      searchMode,
      resourceId,
      autocomplete,
      fields,
      arrayFormat = 'brackets',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...metaData
    } = meta || {};
    if (resourceId) {
      url += `/${resourceId}`;
    }

    // pagination
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const queryFilters = generateFilter(filters, searchMode);

    const generatedSort = generateSort(sort);

    let query: Record<string, any> = {};

    const [filter] = filters as LogicalFilter[];
    if (autocomplete && filter?.field) {
      if (!filter?.field || !filter?.value || filter?.value.length < 3) {
        return {
          data: [],
          total: 0,
        };
      }

      query = {
        model: resource,
        fields: [filter.field],
        term: [filter.value],
        $match: { ...metaData.params },
        $project: fields?.reduce(
          (acc, field) => {
            acc[field as string] = 1;
            return acc;
          },
            {} as any,
        ),
      };

      const { data } = await httpClient.get(`${apiUrl}/autocomplete`, {
        params: {
          ...query,
        },
        paramsSerializer: (params) => Qs.stringify(params, { arrayFormat }),
      }).then((result) => ({ data: { data: result.data, total: 0 } }));

      return {
        data: data.data,
        total: data.total,
      };
    }

    query = {
      $skip: (current - 1) * pageSize,
      $limit: pageSize,
      ...generatedSort,
    };

    const { data } = await httpClient.get(url, {
      params: {
        ...meta?.params,
        ...query,
        ...queryFilters,
        ...queryOptions,
        $select: fields,
      },
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat }),
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  getMany: async ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ids, meta, metaData, resource, ...rest
  }) => {
    const {
      searchKey = '_id',
      options: queryOptions = {},
      resourceId,
      filters: metaFilters = [],
      operation,
      fields = [],
    } = meta || metaData || {};
    const filters = generateFilter(metaFilters);
    let query = {};
    let idsClone:string[] = [];

    if (Array.isArray(ids)) {
      idsClone = ids.reduce<string[]>((acc:string[], id: BaseKey) => {
        if (
          typeof id !== 'undefined' && id
        ) {
          const idStr = (typeof id !== 'string') ? id.toString().toLowerCase() : id.toLowerCase();
          if (acc.indexOf(idStr) === -1) {
            acc.push(idStr);
          }
        }
        return acc;
      }, []);
    }

    if (Array.isArray(searchKey)) {
      query = searchKey
        .reduce((_query, key) => ({
          ..._query,
          $or: [
            ...(_query.$or || []),
            { [key]: { $in: idsClone } },
          ],
        }), {} as any);
    } else if (Array.isArray(idsClone) && idsClone.length === 1) {
      query = { [searchKey]: idsClone[0] };
    } else if (operation === 'in') {
      query = { [`${searchKey}[$in]`]: idsClone };
    } else {
      query = { [searchKey]: idsClone };
    }

    const params = {
      ...(meta || metaData)?.params,
      ...query,
      ...queryOptions,
      ...filters,
      $select: fields,
    };

    let requestURL = `${apiUrl}/${resource}`;
    if (resourceId) {
      requestURL += `/${resourceId}`;
    }
    const { data } = (idsClone.length === 0) ? { data: { data: [] } } : await httpClient.get(
      requestURL,
      {
        params,
        paramsSerializer: (rawParams) => Qs.stringify(rawParams, { arrayFormat: 'brackets' }),
      },
    );

    return {
      data: data.data,
    };
  },

  create: async ({ resource, variables, meta = {} }) => {
    let url = `${apiUrl}/${resource}`;

    if (meta.query) {
      url = `${url}?${queryString.stringify(meta.query)}`;
    }

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(
          `${apiUrl}/${resource}`,
          param,
        );
        return data;
      }),
    );

    return { data: response };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables,
        );
        return data;
      }),
    );

    return { data: response };
  },

  getOne: async ({
    resource, id, metaData: metaLegacy, meta,
  }) => {
    const metaData = meta || metaLegacy;
    const url = `${apiUrl}/${resource}/${id}`;
    const queryOptions = metaData?.options || {};
    const params = metaData?.params || {};

    const { data } = await httpClient.get(url, {
      params: {
        ...params,
        ...queryOptions,
        ...(metaData?.fields ? { $select: metaData?.fields } : {}),
      },
      paramsSerializer: (rawParams) => Qs.stringify(rawParams, { arrayFormat: 'brackets' }),
    });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url);

    return {
      data,
    };
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(
          `${apiUrl}/${resource}/${id}`,
        );
        return data;
      }),
    );
    return { data: response };
  },

  getApiUrl: () => apiUrl,

  custom: async ({
    url, method, filters, sort, payload, query, headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sort) {
      const generatedSort = generateSort(sort);
      if (generatedSort) {
        requestUrl = `${requestUrl}&${Qs.stringify(generatedSort)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${Qs.stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${Qs.stringify(query)}`;
    }

    if (headers) {
      // eslint-disable-next-line no-param-reassign
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers as any,
      };
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url);
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});

export default FeathersDataProvider;
